import immutably from 'immutably';
import {getPathString} from './helpers';
import {
  getPropertyByPath,
  getUniqueName, objectMakerReduceHelper,
  removeFromObject,
  renameObjectKey,
  renameObjectKeyPreserveOrder,
} from '../../lib/helpers';

export const organization = ({ activeCollection, collections, setActiveCollection, setCollections },
    { convertPathStringToPathArray, currentCollection, getCollectionByPath, parentCollection, pathToParent }) => {

  const createCollectionInCurrentCollection = ({name}) => setCollections(
    immutably.set(
      collections,
      `${getPathString(activeCollection)}.collections.${name}`,
      {
        rolls: {},
        collections: {},
      }
    ));
  return {
    createCollection: () => {
      const name = getUniqueName('New Collection', currentCollection.collections);
      createCollectionInCurrentCollection({name});
      return name;
    },

    removeCurrentCollection: () => {
      if (pathToParent === null) {
        return;
      }
      const currentCollectionName = activeCollection.slice(-1)[0];
      const {
        [currentCollectionName]: _,
        ...updatedCollections
      } = parentCollection.collections;
      setActiveCollection(activeCollection.slice(0, -1));
      return setCollections(immutably.set(
        collections,
        `${getPathString(pathToParent)}.collections`,
        updatedCollections
      ));
    },
    duplicate: name => {
      const newCollection = JSON.parse(JSON.stringify(
        currentCollection.collections[name]
      ));
      return setCollections(immutably.set(
        collections,
        `${getPathString(activeCollection)}.collections`,
        {...currentCollection.collections, [`Copy of ${name}`]: newCollection}
      ));
    },
    move: (collectionName, destinationPath) => {
      const currentPath = getPathString(activeCollection)
        .replace(/(^Root)?.collections./g, '/')
        .replace(/^Root/, '/');
      if (destinationPath && destinationPath !== currentPath) {
        const newPath = convertPathStringToPathArray(destinationPath);

        const newCollection = getCollectionByPath(newPath);
        if (typeof getPropertyByPath(newCollection, ['collections']) !== 'object') {
          throw new Error('Bad path!');
        } else if (newCollection.collections.hasOwnProperty(collectionName)) {
          throw new Error(
            'Cannot move this Collection. The destination Collection already has a child Collection by the same name.'
          );
        }
        // add to new location
        const updateStage1 = immutably.set(
          collections,
          `${getPathString(newPath)}.collections`,
          {
            ...newCollection.collections,
            [collectionName]: currentCollection.collections[collectionName],
          }
        );
        // we can't use currentCollection here in case the collection was moved to a child of it
        const updatedCurrentCollection = getCollectionByPath(activeCollection, updateStage1);
        // remove from old location
        const updateStage2 = immutably.set(
          updateStage1,
          `${getPathString(activeCollection)}.collections`,
          removeFromObject(collectionName, updatedCurrentCollection.collections)
        );
        setCollections(updateStage2);
      }
    },
    moveChildCollection: (collectionName, newIndex) => {
      const collArray = Object.entries(currentCollection.collections)
        .filter(([name]) => name !== collectionName);
      const newCollections = [
        ...collArray.slice(0, newIndex),
        [collectionName, currentCollection.collections[collectionName]],
        ...collArray.slice(newIndex),
      ].reduce(objectMakerReduceHelper, {});
      return setCollections(immutably.set(
        collections,
        `${getPathString(activeCollection)}.collections`,
        newCollections
      ));
    },
    renameChildCollection: (oldName, newName, handleConflict) => {
      if (currentCollection.collections.hasOwnProperty(newName)) {
        if (typeof handleConflict === 'function') {
          handleConflict();
        }
      } else {
        const newCollections = renameObjectKeyPreserveOrder(oldName, newName, currentCollection.collections);
        setCollections(immutably.set(
          collections,
          `${getPathString(activeCollection)}.collections`,
          newCollections
        ));
      }
    },
    renameCurrentCollection: () => {
      if (activeCollection.length <= 1) {
        return;
      }
      const newName = window.prompt('Enter new name', '');
      if (!newName) {
        return;
      } else if (parentCollection.collections.hasOwnProperty(newName)) {
        alert(
          'Cannot rename a Collection to be the same as another Collection under the same parent.');
        return;
      }
      const updatedParentCollection = renameObjectKey(
        activeCollection.slice(-1)[0],
        newName,
        parentCollection.collections
      );
      setCollections(immutably.set(
        collections,
        `${getPathString(pathToParent)}.collections`,
        updatedParentCollection
      ));
      setActiveCollection([
        ...pathToParent,
        newName
      ]);
    },
  };
};
