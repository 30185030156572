import React from 'react';

/**
 * @param {React.ReactNode} children
 * @returns {React.Component}
 */
const defaultContainer = ({ children }) => <li>{children}</li>;

/**
 * @typedef TreeViewNode
 * @type {object}
 * @property {string|React.Component} node When this node is displayed, what to show
 * @property {Array.<object.<string, TreeViewTree>>} children Descendants of this node
 */

/**
 * @typedef TreeViewTree
 * @type {Array.<TreeViewNode>}
 */

/**
 * Generate a tree view component, e.g., a folder structure.
 *
 * @example
 * TreeView({ data: {
 *   node: 'root',
 *   children: [
 *     Shared: {
 *       node: 'Shared',
 *       children: [],
 *     }
 *   ]
 * } })
 *
 * @param {object} props - React Props
 * @param {React.ReactNode} props.Container - A component to nest the nodes within
 * @param {TreeViewTree} props.data - The tree to display
 * @returns {React.Component}
 */
const TreeView = ({ Container = defaultContainer, data }) => (
  <ul className="treeview">{
    data.map(({ children, node }, i) => (
      <Container key={i}>
        {node}
        {children
          ? <TreeView Container={Container} data={children} />
          : null}
      </Container>
    ))
  }</ul>
);

export default TreeView;
