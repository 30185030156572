import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CollectionTreeViewSelector from './CollectionTreeViewSelector';
import ErrorAlert from './ErrorAlert';

const SelectCollectionModal = ({ action, collections, pathToExclude, show, setShow, title = 'Select Collection' }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const handleCancel = () => setShow(false);
  const handleOK = (collectionPath) => {
    try {
      action(collectionPath);
      setShow(false);
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  if (!show) {
    return null;
  }

  return (<Modal show={show} onHide={handleCancel}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ErrorAlert {...{errorMessage, setErrorMessage}} />
      <CollectionTreeViewSelector
        {...{
          collections,
          pathToExclude
        }}
        selectCollection={collectionPath => handleOK(collectionPath)}
      />
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleCancel}>
        Cancel
      </Button>
    </Modal.Footer>
  </Modal>);
};

export default SelectCollectionModal;
