import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ConfirmationModal = ({ action, show, setShow, text, title = 'Are you sure?' }) => {
  const handleCancel = () => setShow(false);
  const handleOK = () => {
    action();
    setShow(false);
  };

  if (!show) {
    return null;
  }

  return (<Modal show={show} onHide={handleCancel}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{text}</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleCancel}>
        Cancel
      </Button>
      <Button variant="danger" onClick={handleOK}>
        OK
      </Button>
    </Modal.Footer>
  </Modal>);
};

export default ConfirmationModal;
