import React, {useState} from 'react';
import { FormulaEditor } from './FormulaEditor';
import Card from 'react-bootstrap/Card';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import ClickableValue from '../common/ClickableValue';
import DragHandle from '../common/DragHandle';
import ConfirmationModal from '../common/ConfirmationModal';

const SavedValue = ({ activateFormulaInCalculator, formula, name, remove, rename, rollMetadata, saveRoll }) => {
  const [editableFormula, setEditableFormula] = useState(false);
  const [renaming, setRenaming] = useState(false);
  const [modalProperties, setModalProperties] = useState({
    action: () => {}, show: false, text: ''
  });
  const doWithConfirmation = (action, text) => setModalProperties({
    action,
    show: true,
    text
  });
  const hideValue = rollMetadata.value.join(',') === formula;
  return (<>
    <Card className="mr-1 mb-1">
      <Card.Header>
        {!renaming && <DragHandle/>}
        <ClickableValue
          updateOnBlur
          setEditing={setRenaming}
          setValue={newName => rename(name, newName)}
          value={name}
        />
        {!renaming && <FontAwesomeIcon
          className="danger trash-button"
          icon={faTrashAlt}
          onClick={() => doWithConfirmation(remove, "Click OK to delete this saved value.")}
          title="Delete this saved value"
        />}
      </Card.Header>
      <Card.Body>
        {
          hideValue
            ? null
            : <Card.Title
              onClick={activateFormulaInCalculator}
            >{rollMetadata.value.join(', ')}
            </Card.Title>
        }

        <Card.Text
          className="text-muted"
        >
          <FormulaEditor
            controlled={false}
            editButton={true}
            formula={formula}
            name={name}
            saveRoll={saveRoll}
            readOnlyProp={!editableFormula}
            setReadOnlyProp={ro => setEditableFormula(!ro)}
          />
        </Card.Text>
      </Card.Body>
    </Card>
    <ConfirmationModal
      setShow={show => setModalProperties({ ...modalProperties, show })}
      {...modalProperties}
    />
  </>);
};

export default SavedValue;
