import {getPropertyByPath} from '../../lib/helpers';

export const navigation = ({ activeCollection, setActiveCollection, collections }) => {
  const getCollectionByPathReduceHelper = (accum, pathSegment, i, path) => i < path.length - 1
    ? [...accum, pathSegment, 'collections']
    : [...accum, pathSegment];
  /**
   * @example
   * getCollectionByPath(['Root', 'Example PC - Rogue', 'saves'])
   *
   * @param {string[]} path
   * @param {SavedCollections} collectionsToSearch
   * @returns {SavedCollection}
   */
  const getCollectionByPath = (path, collectionsToSearch = collections) =>
    getPropertyByPath(collectionsToSearch,
      path.reduce(getCollectionByPathReduceHelper, [])
    );
  const getCurrentPath = () => `${activeCollection.join('/')}`.replace(/^Root\//, '/');
  return {
    /**
     * @function
     * @example // returns ['Root', 'Example PC - Rogue', 'saves']
     * convertPathStringToPathArray('/Example PC - Rogue/saves')
     * @param {string} path
     * @returns {string[]}
     */
    convertPathStringToPathArray: path =>
      (
        path.substring(0,1) === '/'
          ? path
          : `${getCurrentPath()}/${path}`
      ).replace(/^\/?/, 'Root/')
      .split('/')
      .filter(_ => _),
    getCollectionByPath,
    getCollectionByPathReduceHelper,
    getCurrentPath,
    navigateToRoot: () => setActiveCollection(['Root']),
    navigateToParent:
      (parentNumber = 1) => setActiveCollection(activeCollection.slice(0, 0 - parentNumber)),
    navigateTo: collection => setActiveCollection([
      ...activeCollection,
      collection
    ]),
    currentCollection: getCollectionByPath(activeCollection),
    pathToParent: activeCollection.slice(0, -1),
    parentCollection: activeCollection.length > 1
      ? getCollectionByPath(activeCollection.slice(0, -1))
      : null,
  };
};
