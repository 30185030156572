import React from "react";
import { Form, FormControl } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";

// child of SavedRoll

const VariableEntry = ({
  onlyUnknownVariable,
  rollMetadata,
  setSuppliedValues,
  suppliedValues,
  valuesFromTargetedCollections
}) => (
  <Form>
    <Form.Group>
      <Form.Label column={false}>Unknown variables</Form.Label>
      {rollMetadata.unknownVariables.map((variableName, i) => (
        <InputGroup key={`unknown-${i}`}>
          <InputGroup.Prepend>
            <InputGroup.Text>{variableName}</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            className="unknown-variable-value"
            onChange={e =>
              setSuppliedValues({
                ...suppliedValues,
                [variableName]: e.target.value
              })
            }
            placeholder={
              rollMetadata.noPromptVariables.includes(variableName)
                ? "0"
                : onlyUnknownVariable === variableName
                  ? "?"
                  : null
            }
            readOnly={valuesFromTargetedCollections.hasOwnProperty(
              variableName
            )}
            value={
              valuesFromTargetedCollections.hasOwnProperty(variableName)
                ? valuesFromTargetedCollections[variableName]
                : suppliedValues[variableName] || ""
            }
          />
        </InputGroup>
      ))}
    </Form.Group>
  </Form>
);

export default VariableEntry;
