import React from 'react';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDice } from '@fortawesome/free-solid-svg-icons';

const RollButton = ({
  hasMissingVariables,
  onlyUnknownVariable,
  roll,
  size = "lg",
  suppliedValues,
  targetCollections,
  valuesFromTargetedCollections,
}) => (<Button
  block
  className={size === 'lg' ? "mr-1 p-4" : ""}
  disabled={hasMissingVariables}
  onClick={event => {
    event.stopPropagation();
    if (onlyUnknownVariable) {
      roll({
        ...suppliedValues,
        ...valuesFromTargetedCollections,
        [onlyUnknownVariable]: '?',
      }, targetCollections);
    } else {
      roll({ ...suppliedValues, ...valuesFromTargetedCollections }, targetCollections);
    }
  }}
  size={size}
  title="Roll!"
  variant="outline-primary"
>
  <FontAwesomeIcon
    className={hasMissingVariables ? 'secondary' : 'success'}
    icon={faDice}
    size="2x"
  />
</Button>);

export default RollButton;
