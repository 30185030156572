import React from 'react';
import PropTypes from 'prop-types';
import ButtonWithHelpText from '../common/ButtonWithHelpText';

const CalculatorButton = ({ className, children, ...otherProps }) => (
  <ButtonWithHelpText
    className={`calculator-button ${className ? className : ''}`}
    {...otherProps}
  >{children}</ButtonWithHelpText>
);

CalculatorButton.propTypes = {
  action: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default CalculatorButton;
