import React from 'react';
import Alert from 'react-bootstrap/Alert';

const ErrorMessage = ({ errorMessage, setErrorMessage }) => (
  errorMessage
    ? <Alert
      dismissible
      onClose={() => setErrorMessage(null)}
      variant="danger"
    >
      {errorMessage}
    </Alert>
    : null
);

export default ErrorMessage;
