import { useState, useEffect, useCallback, useRef } from 'react';
/* eslint fp/no-mutation: ['error', {exceptions: [{property:'current'}]}] */

/**
 * A custom React Hook that supports a hover / long press action (for mobile) and onClick action.
 * It ensures that if the long press action is called, the onClick action will not be called (unless
 * the user clicks again).
 *
 * @param {Function} holdAction Call this when the long press is triggered
 * @param {Function} cancelHoldAction After longPressAction has been called, if the triggering event ends, call this.
 * @param {Function} clickAction Action to take on click
 * @param {number} delay Time to wait from mouse down to executing the longPressAction, in milliseconds
 * @returns {object.<string, Function>} Action handler props for the target component
 */

export default function useHoldOrClick({
  holdAction = () => {},
  cancelHoldAction = () => {},
  clickAction = () => {},
  delay = 1000,
}) {
  const [holding, setHolding] = useState(false);
  const doClickAction = useRef(false);
  const holdTimer = useRef(0);

  useEffect(() => {
    if (holding) {
      holdTimer.current = setTimeout(() => {
        doClickAction.current = false;
        holdAction();
      }, delay);
    } else {
      clearTimeout(holdTimer.current);
      holdTimer.current = 0;
    }

    return () => {
      clearTimeout(holdTimer.current);
      holdTimer.current = 0;
    };
  }, [holdAction, delay, holding]);

  const start = useCallback(() => {
    setHolding(true);
  }, []);

  const stop = useCallback(() => {
    setHolding(false);
    cancelHoldAction();
  }, [cancelHoldAction]);

  const startHolding = useCallback(event => {
    if (holdTimer.current) {
      clearTimeout(holdTimer.current);
    }
    doClickAction.current = true;
    start();
  }, [start]);

  const stopHolding = useCallback(event => {
    if (doClickAction.current) {
      doClickAction.current = false;
      clickAction();
    }
    stop();
    event.preventDefault();
  }, [clickAction, stop]);

  return {
    onMouseDown: startHolding,
    onMouseUp: stopHolding,
    onMouseEnter: start,
    onMouseLeave: () => {
      doClickAction.current = false;
      stop();
    },
    onTouchStart: startHolding,
    onTouchEnd: stopHolding,
  };
}
