import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './history.css';
import ResultBuilder from './common/ResultBuilder';
import {getAllRolls} from "roll-a-d6/lib/helpers";

const Helper = ({ name, value, source }) => (
  <li>
    <strong>{name}</strong> (from {source}): {value}
  </li>
);
const Helpers = ({ helpers }) => {
  return <ul className="helpers-details">
    {
      helpers.map((props, i) => <Helper key={i} {...props} />)
    }
  </ul>;
};

const HistoryPane = ({ formula, helpers, result, rolls, scrollTo, setScrollTo }) => {
  const [active, setActive] = useState(false);
  const ref = useRef();
  useEffect(() => {
    if (scrollTo) {
      setActive(true);
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      return () => {
        setScrollTo(null);
      }
    }
  }, [ref, scrollTo, setScrollTo]);
  const handleClick = event => {
    if (ref.current.contains(event.target)) {
      event.preventDefault();
      setActive(true);
    } else {
      setActive(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
  return (
    <li ref={ref} className={`history-item ${active ? 'active' : ''}`}>
      <div className="formula">Formula: {formula}</div>
      <div className="result">Result: <strong><ResultBuilder result={result} /></strong></div>
      <div className="rolls">Roll Detail: <em>{
        Array.isArray(rolls)
            ? rolls.length === 0 ? 'None' : rolls.join(', ')
            : getAllRolls(rolls).length === 0 ? 'None' : getAllRolls(rolls).join(', ')
      }</em></div>
      {
        active && helpers
          ? <div className="input-details">Helpers Detail: <Helpers helpers={helpers} /></div>
          : ''
      }
    </li>
  )
};

HistoryPane.propTypes = {
  formula: PropTypes.string.isRequired,
  result: ResultBuilder.propTypes.result,
  rolls: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType(
      [PropTypes.number, PropTypes.string]).isRequired).isRequired,
    PropTypes.objectOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))).isRequired
  ]).isRequired,
  helpers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    source: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  }).isRequired),
};

const History = ({ clearHistory, history, scrollTo, setScrollTo }) => {
  return (<div className="history">
    <h1>History</h1>
    <div className="history-body">
      <ul>
        <li>
          <button className="clear-history" onClick={clearHistory}>Clear History</button>
        </li>
        {
          history.map((historyItem, i) => (
            <HistoryPane
              key={`history-pane-${i}`}
              scrollTo={scrollTo===i}
              setScrollTo={setScrollTo}
              {...historyItem}
            />
          ))
        }
      </ul>
    </div>
  </div>);
};


History.propTypes = {
  clearHistory: PropTypes.func.isRequired,
  history: PropTypes.arrayOf(PropTypes.shape(HistoryPane.propTypes).isRequired).isRequired,
  scrollTo: PropTypes.number,
  setScrollTo: PropTypes.func.isRequired,
};

export default History;
