import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ResultBuilder from '../common/ResultBuilder';
import './calculator-display.css';

const CalculatorDisplay = ({ formula, setFormula, result, rolls }) => {
  const [formulaReadOnly, setFormulaReadOnly] = useState(true);

  const blurFormulaEditor = () => {
    setFormulaReadOnly(true);
    setFormula(formula.replace(/\s+/g, ','));
  };
  const enableFormulaEditor = () => {
    setFormulaReadOnly(false);
    setFormula(formula.replace(/,/g, '\n'));
  };
  const softBreaks = () => formula.split('\n').reduce((accum, line) => accum + Math.floor(10 * line.length / window.innerWidth), 0);
  const formulaLineCount = () => (formula.match(/\n/g) || []).length + softBreaks() + 1;


  return (<div className="calculator-display">
      {
        formulaReadOnly
          ? (<>
            <Row className="formula" onClick={enableFormulaEditor}>
              <Col xs={12}>
                <strong>Enter Formula:</strong> {formula}
              </Col>
            </Row>
            <Row className="result">
              <Col xs={12} className="text-right">
                <ResultBuilder result={result} />
              </Col>
            </Row>
            <Row className="rolls">
              <Col xs={12} className="text-right">
                Roll Detail: {(Array.isArray(rolls) ? rolls : Object.values(rolls).flat()).join(', ')}
              </Col>
            </Row>
          </>)
          : (<Row>
              <textarea
                autoFocus
                className="formula-editor"
                onBlur={blurFormulaEditor}
                onChange={e => setFormula(e.target.value)}
                rows={Math.min(3, formulaLineCount())}
                value={formula}
              />
          </Row>)
      }
    </div>);
}

export default CalculatorDisplay;
