import React from 'react';
import PropTypes from 'prop-types';
import './calculator-buttons.css';
import CalculatorButton from './CalculatorButton';
import CalculatorDisplay from './CalculatorDisplay';
import { ReactComponent as D4 } from '../../assets/D4.svg';
import { ReactComponent as D6 } from '../../assets/D6.svg';
import { ReactComponent as D8 } from '../../assets/D8.svg';
import { ReactComponent as D10 } from '../../assets/D10.svg';
import { ReactComponent as D12 } from '../../assets/D12.svg';
import { ReactComponent as D20 } from '../../assets/D20.svg';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const expansionInfo = (operator, expansionOperator) => `a #${expansionOperator || `${operator}`} b - Expand into b ${operator} b ${operator} b... where b is repeated a times. NOTE: b may only be a single roll, value, or variable - it cannot be enclosed in parentheses.`;

const Calculator = ({
  formula, result, rolls, roll, saveRoll, setFormula,
}) => {
  const add = character => () => setFormula(`${formula}${character}`);
  const backspace = () => setFormula(formula.slice(0,-1));
  const clear = () => setFormula('');

  const CalcButton = ({ children, ...props }) => (
    <CalculatorButton action={add(props.val || children)} {...props}>{children}</CalculatorButton>
  );
  const DiceButton = ({ children, sides, ...otherProps }) => (
    <CalcButton
      className="dice"
      info={`A ${sides || '??'} sided die.${sides ? '' : ' You need to enter the number of sides yourself.'}`}
      val={`${/\d/.test(formula.slice(-1)) ? '' : '1'}d${sides || ''}`}
      variant="outline-success"
      {...otherProps}
    >
      {children}
    </CalcButton>
  );
  return (<Container className="calculator">
    <h1>Calculator</h1>
    <CalculatorDisplay
      formula={formula}
      setFormula={setFormula}
      result={result}
      rolls={rolls}
    />
    <Row className="buttons" noGutters>
      <Col xs={9}>
        <Row noGutters>
          <Col className="mb-1" sm={10} md={6} lg={4}>
            <CalcButton className="number">7</CalcButton>
            <CalcButton className="number">8</CalcButton>
            <CalcButton className="number">9</CalcButton>
            <CalcButton className="arithmetic">+</CalcButton>
            <CalcButton className="number">4</CalcButton>
            <CalcButton className="number">5</CalcButton>
            <CalcButton className="number">6</CalcButton>
            <CalcButton className="arithmetic">-</CalcButton>
            <CalcButton className="number">1</CalcButton>
            <CalcButton className="number">2</CalcButton>
            <CalcButton className="number">3</CalcButton>
            <CalcButton className="arithmetic">*</CalcButton>
            <CalcButton className="number">0</CalcButton>
            <CalcButton className="arithmetic" info="A grouping operator">(</CalcButton>
            <CalcButton className="arithmetic" info="A grouping operator">)</CalcButton>
            <CalcButton className="arithmetic" info="Integer division">/</CalcButton>
          </Col>
          <Col className="mb-1" sm={10} md={6} lg={4}>
            <DiceButton sides={20}><D20 className="success" height="45px" width="45px" /></DiceButton>
            <DiceButton sides={12}><D12 className="success" height="45px" width="45px" /></DiceButton>
            <DiceButton sides={10}><D10 className="success" height="45px" width="45px" /></DiceButton>
            <DiceButton sides={8}><D8 className="success" height="45px" width="45px" /></DiceButton>
            <DiceButton sides={6}><D6 className="success" height="45px" width="45px" /></DiceButton>
            <DiceButton sides={4}><D4 className="success" height="45px" width="45px" /></DiceButton>
            <DiceButton sides={100}>d100</DiceButton>
            <DiceButton sides={null}>d?</DiceButton>
          </Col>
          <Col sm={10} md={6} lg={4}>
            <CalcButton className="compare" info="a<b - is a less than b?">{'<'}</CalcButton>
            <CalcButton className="compare" info="a<=b - is a less than or equal to b?">{'<='}</CalcButton>
            <CalcButton className="compare" info="a>b - is a greater than b?">{'>'}</CalcButton>
            <CalcButton className="compare" info="a>=b - is a greater than or equal to b?">{'>='}</CalcButton>
            <CalcButton className="compare" info="a==b - is a equal to b?">{'=='}</CalcButton>
            <CalcButton className="compare" info="a<>b - is a not equal to b?">{'<>'}</CalcButton>
            <CalcButton className="compare" info="a>>b - returns the greater of a and b. NOTE: can be chained (4>>5>>6>>3 returns 6)">Max</CalcButton>
            <CalcButton className="compare" info="a<<b - returns the lesser of a and b. NOTE: can be chained (4<<5<<3<<6 returns 3)">Min</CalcButton>
            <CalcButton
              info="Assign to a single variable in your formula to see multiple results for different values of the variable"
              variant="outline-secondary"
            >?</CalcButton>
            <CalcButton info="a||b - a OR b - if a is true or non-zero, then a, otherwise b" variant="outline-secondary">||</CalcButton>
            <CalcButton info="a&&b - a AND b - if a is true or non-zero, then b, otherwise a" variant="outline-secondary">&&</CalcButton>
            <CalcButton
              info="After a conditional, use this to set your result to the next expression if the conditional is true, or keep the expression's value otherwise, e.g., 1d6==6->10 (returns either false or 10)"
              val='->'
              variant="outline-secondary"
            >Then</CalcButton>
            <CalcButton
              info="Else - Use when you want a value other than false if your conditional fails, e.g., 1d6>5;55 (returns true on a 6 and 55 otherwise). Use in conjunction for maximum power: 1d20>10->1d8+5;0"
              val=";"
              variant="outline-secondary"
            >Else</CalcButton>
          </Col>
          <Col sm={10} md={6} lg={4}>
            <CalcButton className="expansion" info={expansionInfo('+')}>#+</CalcButton>
            <CalcButton className="expansion" info={expansionInfo('*')}>#*</CalcButton>
            <CalcButton className="expansion" info={expansionInfo('||', '|')}>#|</CalcButton>
            <CalcButton className="expansion" info={expansionInfo('&&', '&')}>#&</CalcButton>
            <CalcButton className="macro" info="a[0] - Local instance variable. Use this to refer to the same macro evaluation in case you need to check a value twice.">[</CalcButton>
            <CalcButton className="macro" info="a[0] - Local instance variable. Use this to refer to the same macro evaluation in case you need to check a value twice.">]</CalcButton>
            <CalcButton className="macro" info="a{0} - Use to refer to an instance variable that is preserved across the entire roll">{'{'}</CalcButton>
            <CalcButton className="macro" info="a{0} - Use to refer to an instance variable that is preserved across the entire roll">{'}'}</CalcButton>
            <CalcButton className="macro" info="Assign a value or macro to a variable, e.g., modifier=5,1d6+modifier">=</CalcButton>
            <CalcButton className="macro" info="Indicates a variable should be 0 if not provided, e.g., ^adv">^</CalcButton>
            <CalcButton className="macro" info="Designates variables that should come from a collection, e.g., ac@Defender">@</CalcButton>
            <CalcButton className="macro" info="Separates clauses - either assignments or distinct results.">,</CalcButton>
          </Col>
        </Row>
      </Col>
      <Col xs={3}>
        <CalculatorButton action={backspace} className="func">⌫</CalculatorButton>
        <CalculatorButton action={clear} className="func" info="Clear the formula entered into the calculator">Clear</CalculatorButton>
        <CalculatorButton action={saveRoll} className="save func" info="Save this roll into the current collection">Save Roll</CalculatorButton>
        <CalculatorButton action={roll} className="roll func">Roll!</CalculatorButton>
      </Col>
    </Row>
  </Container>);
};

Calculator.propTypes = {
  formula: PropTypes.string.isRequired,
  result: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]).isRequired).isRequired,
  rolls: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  roll: PropTypes.func.isRequired,
  saveRoll: PropTypes.func.isRequired,
  setFormula: PropTypes.func.isRequired,
};

export default Calculator;
