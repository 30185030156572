import { useState } from 'react';

const fetchFromLocalStorage = (key, defaultValue) => {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : defaultValue;
};

const setWrapper = (key, setter) => value => {
  localStorage.setItem(key, JSON.stringify(value));
  setter(value);
};

const traveler = (value, setter, historyValue, historySetter, futureValue, futureSetter) => ({
  moveBack: () => {
    futureSetter([value, ...futureValue]);
    setter(historyValue.slice(-1)[0]);
    historySetter(historyValue.slice(0, -1));
  },
  moveForward: () => {
    historySetter([...historyValue, value]);
    setter(futureValue[0]);
    futureSetter(futureValue.slice(1));
  },
  viewTimeline: () => {
    return {
      past: historyValue,
      present: value,
      future: futureValue
    };
  },
});

const historyHelpers = (key, value, setter, historyValue, historySetter, futureValue, futureSetter) => {
  const historyEnabledSetter = (newValue) => {
    setWrapper(`${key}History`, historySetter)([...historyValue, value]);
    setWrapper(key, setter)(newValue);
  };
  const timeTraveler = traveler(value, setter, historyValue, historySetter, futureValue, futureSetter);
  return [value, historyEnabledSetter, timeTraveler];

};
const useStateWrapper = (key, defaultValue, enableHistory = false, transformStartingValue) => {
  const startingValue = transformStartingValue
    ? transformStartingValue(fetchFromLocalStorage(key, defaultValue))
    : fetchFromLocalStorage(key, defaultValue);
  const [value, setter] = useState(startingValue);
  // outside the conditional because the order for React hooks is important
  const startingHistoryValue = fetchFromLocalStorage(`${key}History`, []);
  const [historyValue, historySetter] = useState(startingHistoryValue);
  const startingFutureValue = fetchFromLocalStorage(`${key}Future`, []);
  const [futureValue, futureSetter] = useState(startingFutureValue);
  if (enableHistory) {
    return historyHelpers(key, value, setter, historyValue, historySetter, futureValue, futureSetter);
  } else {
    return [value, setWrapper(key, setter)];
  }
};

const AppState = () => {
  const [activeTab, setActiveTab] = useStateWrapper('activeTab', 'calculator');
  const [history, setHistory] = useStateWrapper('history', []);
  const [formula, setFormula] = useStateWrapper('formula', '');
  const [result, setResult] = useStateWrapper('result', ['Not Rolled']);
  const [rolls, setRolls] = useStateWrapper('rolls', []);
  const [scrollToItem, setScrollToItem] = useState(null);
  const pushHistoryItem = historyItem => setHistory([...history, historyItem]);

  return {
    activeTab, setActiveTab,
    history, setHistory,
    formula, setFormula,
    result, setResult,
    rolls, setRolls,
    scrollToItem, setScrollToItem,
    pushHistoryItem,
  };
};

export default AppState;
export { useStateWrapper };
