/**
 * This helper enables mapping over the entries in an object that is being used like a Map.
 * @example
 * // returns { a: 2, b: 4 }
 * Object.entries({ a: 1, b: 2 }).map(([k,v]) => [k, v*2]).reduce(objectMakerReducerHelper, {})
 * @param {object} accum (Accumulator) This accumulates the return values of this function
 * @param {[string, *]} currentValue The next key-value pair to be added to the accumulator
 * @returns {object}
 */
export const objectMakerReduceHelper = (accum, [key, val]) => ({ ...accum, [key]: val });

/**
 * Immutably removes a key from an object
 * @example
 * // returns { a: 1 }
 * removeFromObject('b', { a: 1, b: 2 })
 *
 * @param {string} key The key to remove
 * @param {object} obj The object to update
 * @returns {object} A copy of obj, with the named key removed
 */
export const removeFromObject = (key, { [key]: _, ...otherEntities }) => ({ ...otherEntities });

/**
 * Immutably renames a key in an object. WARNING: Key order is not preserved.
 * @param {string} oldKey
 * @param {string} newKey
 * @param {object} obj
 * @returns {object} obj, with oldKey:value removed and newKey:value added
 */
export const renameObjectKey = (oldKey, newKey, { [oldKey]: value, ...otherEntities}) => (
  {
    [newKey]: value,
    ...otherEntities
  }
);

/**
 * Immutably renames a key in an object without potentially changing the keys' order
 * @param {string} oldKey
 * @param {string} newKey
 * @param {object} obj
 * @returns {object} obj, with oldKey:value removed and newKey:value added, and with order preserved
 */
export const renameObjectKeyPreserveOrder = (oldKey, newKey, obj) =>
  Object.entries(obj)
    .map(([key, val]) => key === oldKey ? [newKey, val] : [key, val])
    .reduce(objectMakerReduceHelper, {});

/**
 * Descend into a nested object/array by following the path provided. Fallback to undefined.
 * @example // returns 'bar'
 * getPropertyByPath({a:{b:[['foo','bar']]}}, ['a','b',0,1])
 *
 * @param {object|Array|*} value
 * @param {string[]} path
 * @returns {undefined|*}
 */
export const getPropertyByPath = (value, path) =>
  (path.length === 0
    ? value
    : value && typeof value === 'object'
      ? getPropertyByPath(value[path[0]], path.slice(1))
      : undefined);

/**
 * Given an object and a desired key name, find the first instance of `${name}` or `${name} ${number}`
 * that isn't already taken.
 * @example // returns 'Corvec 2'
 * getUniqueName('Corvec', { Corvec: 100, 'Corvec 1': 50 })
 *
 * @param {string} name
 * @param {object} container
 * @returns {string}
 */
export const getUniqueName = (name, container) => {
  if (!container.hasOwnProperty(name)) {
    return name;
  }

  const getName = i => `${name} ${i}`;
  const getNextName = i =>
    container.hasOwnProperty(getName(i))
      ? getNextName(i+1)
      : getName(i);
  return getNextName(1);
};
