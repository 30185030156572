import React from 'react';
import Card from 'react-bootstrap/Card';

const CollapsedSavedValue = ({ name, value, ...props }) => (
  <Card>
    <Card.Body {...props}>
      {name}: {value}
    </Card.Body>
  </Card>
);

export default CollapsedSavedValue;
