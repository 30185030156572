import React, { useEffect, useState } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import PropTypes from 'prop-types';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import '../../buttons.css';

const ClickableValue = ({
  forceEditable = false,
  inputStyle = {},
  onClick = null,
  setEditing = () => {},
  spanStyle = {},
  updateOnBlur = false,
  setValue,
  value
}) => {
  const [isEditable, setEditableWrapped] = useState(forceEditable);
  const setEditable = value => {
    setEditableWrapped(value);
    setEditing(value);
  };
  const [unsavedValue, setUnsavedValue] = useState(value);
  useEffect(() => {
    if (updateOnBlur) {
      setUnsavedValue(value);
    }
  }, [updateOnBlur, value]);

  const handleBlur = () => {
    if (updateOnBlur) {
      setValue(unsavedValue);
    }
    setEditable(false);
  };

  const handleChange = (event) => {
    if (updateOnBlur) {
      setUnsavedValue(event.target.value);
    } else {
      setValue(event.target.value);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleBlur();
      event.preventDefault();
    }
  };

  const handleSpanClick = (event) => {
    if (onClick === null) {
      setEditable(true);
    } else {
      onClick(event);
    }
  };
  const handleEditButtonClick = event => {
    event.preventDefault();
    event.stopPropagation();
    setEditable(true);
  };

  if (isEditable) {
    return <FormControl
      autoFocus
      className={inputStyle}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      style={inputStyle}
      value={updateOnBlur ? unsavedValue : value}
    />
  }
  return <>
    <span
      onClick={handleSpanClick}
      style={spanStyle}
    >
      {value}
    </span>
    {
      onClick !== null
        ? <Button
          onClick={handleEditButtonClick}
          variant="invisible"
        >
          <FontAwesomeIcon
            icon={faEdit}
            size="xs"
          />
        </Button>
        : null
    }
    </>;
};

ClickableValue.propTypes = {
  inputStyle: PropTypes.objectOf(PropTypes.string),
  onClick: PropTypes.func,
  spanStyle: PropTypes.objectOf(PropTypes.string),
  updateOnBlur: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default ClickableValue;
