const defaultCollectionsState = {
  Root: {
    collections: {
      Shared: {
        rolls: {
          roll: 'r=1d20,(^adv&&^disadv)||(adv==0&&disadv==0)->r[0];(adv->r[0]>>r[1];r[0]<<r[1])',
          atk: 'roll[0]==20->dmgDice[0]+dmgDice[1]+dmgMod;(roll[0]==1->0;(roll[0]+bonus>=ac@Defender->dmgDice[0]+dmgMod;0))',
          bonus: 'prof+dmgMod',
        },
        collections: {
          armor: {
            collections: {},
            rolls: {
              shield: '2',
              leatherArmor: '11+Dex',
              paddedArmor: '11+Dex,badSneak=1',
              studdedLeatherArmor: '12+Dex',
              hideArmor: '12+(Dex<<2)',
              chainShirt: '13+(Dex<<2)',
              scaleMail: '14+(Dex<<2),badSneak=1',
              breastPlate: '14+(Dex<<2),badSneak=1',
              halfPlate: '15+(Dex<<2),badSneak=1',
              ringMail: '14,badSneak=1',
              chainMail: '16,badSneak=1,armorSpeedPenalty=Str<13->-10;0',
              splintArmor: '17,badSneak=1,armorSpeedPenalty=Str<15->-10;0',
              plateArmor: '18,badSneak=1,armorSpeedPenalty=Str<15->-10;0',
            },
          },
          weapons: {
            collections: {},
            rolls: {
              club: '1d4,dmgMod=Str',
              dagger: '1d4,dmgMod=Dex>>Str,range=20,longRange=60',
              greatClub: '1d8,dmgMod=Str',
              handAxe: '1d6,dmgMod=Str,range=20,longRange=60',
              javelin: '1d6,dmgMod=Str,range=30,longRange=120',
              lightHammer: '1d4,dmgMod=Str,range=20,longRange=60',
              mace: '1d6,dmgMod=Str',
              quarterstaff: '^twoHanded->1d8;1d6,dmgMod=Str',
              sickle: '1d4,dmgMod=Str',
              spear: '1d6,dmgMod=Str,range=20,longRange=60',
              unarmedStrike: '1,dmgMod=Str',
              lightCrossbow: '1d8,dmgMod=Dex,range=80,longRange=320',
              dart: '1d4,dmgMod=Dex>>Str,range=20,longRange=60',
              shortbow: '1d6,dmgMod=Dex,range=80,longRange=320',
              sling: '1d4,dmgMod=Dex,range=30,longRange=120',
              battleaxe: '^twoHanded->1d10;1d8,dmgMod=Str',
              flail: '1d8,dmgMod=Str',
              glaive: '1d10,dmgMod=Str,reach=1',
              greataxe: '1d12,dmgMod=Str',
              greatsword: '2d6,dmgMod=Str',
              halberd: '1d10,dmgMod=Str,reach=1',
              lance: '1d12,dmgMod=Str,reach=1',
              longSword: '^twoHanded->1d10;1d8,dmgMod=Str',
              maul: '2d6,dmgMod=Str',
              morningstar: '1d8,dmgMod=Str',
              pike: '1d10,dmgMod=Str,reach=1',
              rapier: '1d8,dmgMod=Dex>>Str',
              scimitar: '1d6,dmgMod=Dex>>Str',
              shortSword: '1d6,dmgMod=Dex>>Str',
              trident: '^twoHanded->1d8;1d6,dmgMod=Str,range=20,longRange=60',
              warPick: '1d8,dmgMod=Str',
              warhammer: '^twoHanded->1d10;1d8,dmgMod=Str',
              whip: '1d4,dmgMod=Dex>>Str,reach=1',
              blowgun: '1,dmgMod=Dex,range=25,longRange=100',
              handCrossbow: '1d6,dmgMod=Dex,range=30,longRange=120',
              heavyCrossbow: '1d10,dmgMod=Dex,range=100,longRange=400',
              longbow: '1d8,dmgMod=Dex,range=150,longRange=600',
              net: '0,dmgMod=0,range=5,longRange=15',
            },
          },
        },
      },
      Default: {
        rolls: {
          "Roll Starting Stats (4d6 drop lowest)": "die=1d6,stat=die[0]+die[1]+die[2]+die[3]-(die[0]<<die[1]<<die[2]<<die[3]),stat,stat,stat,stat,stat,stat",
        },
        collections: {},
      },
      'Example PC - Fighter': {
        rolls: {
          initiative: 'roll+Dex',
          duelingStyle: '^ranged||^twoHanded->0;2',
          'Attack with Long Sword': 'dmgDice=longSword,dmgMod=Str+duelingStyle,bonus=prof+Str,atk+atk',
          secondWind: '1d10+5',
          maneuverSaveDC: '8+prof+Str',
          superiorityDie: '1d8',
          Str: '4',
          Dex: '2',
          Con: '3',
          Int: '0',
          Wis: '1',
          Cha: '-1',
          prof: '3',
          AC: 'chainMail+shield',
        },
        collections: {
          saves: {
            rolls: {
              strSave: 'roll+Str+prof',
              dexSave: 'roll+Dex',
              conSave: 'roll+Con+prof',
              intSave: 'roll+Int',
              wisSave: 'roll+Wis',
              chaSave: 'roll+Cha',
            },
            collections: {},
          },
          skills: {
            rolls: {
              Athletics: 'roll+Str+prof',
              Acrobatics: 'roll+Dex',
              'Sleight of Hand': 'roll+Dex',
              Stealth: 'roll+Dex',
              Arcana: 'roll+Int',
              History: 'roll+Int',
              Investigation: 'roll+Int',
              Nature: 'roll+Int',
              Religion: 'roll+Int',
              'Animal Handling': 'roll+Wis',
              Insight: 'roll+Wis',
              Medicine: 'roll+Wis',
              Perception: 'roll+Wis+prof',
              Survival: 'roll+Wis',
              Deception: 'roll+Cha',
              Intimidation: 'roll+Cha',
              Performance: 'roll+Cha',
              Persuasion: 'roll+Cha',
            },
            collections: {},
          },
        },
      },
      'Example PC - Rogue': {
        rolls: {
          initiative: 'roll+Dex+Cha',
          'Main Hand': 'dmgDice=shortSword+sneakAttackIfPossible,atk',
          'Off Hand': 'dmgMod=0,dmgDice=shortSword+sneakAttackIfPossible,atk',
          'Both Attacks': 'dmgDice=shortSword,(atk[0]+atk[1])>0->(atk[0]+atk[1]+sneakAttackIfPossible);0',
          sneakAttackIfPossible: 'canYouSneakAttack->sneakAttack;0',
          Str: '-1',
          Dex: '4',
          Con: '2',
          Int: '0',
          Wis: '1',
          Cha: '3',
          prof: '3',
          AC: 'studdedLeatherArmor',
          sneakAttack: '3d6',
        },
        collections: {
          saves: {
            rolls: {
              strSave: 'roll+Str',
              dexSave: 'roll+Dex+prof',
              conSave: 'roll+Con',
              intSave: 'roll+Int+prof',
              wisSave: 'roll+Wis',
              chaSave: 'roll+Cha',
            },
            collections: {},
          },
          skills: {
            rolls: {
              Athletics: 'roll+Str',
              Acrobatics: 'roll+Dex+prof',
              'Sleight of Hand': 'roll+Dex',
              Stealth: 'roll+Dex+prof',
              'Thieves\' Tools': 'roll+Dex+2*prof',
              Arcana: 'roll+Int',
              History: 'roll+Int',
              Investigation: 'roll+Int',
              Nature: 'roll+Int',
              Religion: 'roll+Int',
              'Animal Handling': 'roll+Wis',
              Insight: 'roll+Wis',
              Medicine: 'roll+Wis',
              Perception: 'roll+Wis+2*prof',
              Survival: 'roll+Wis',
              Deception: 'roll+Cha',
              Intimidation: 'roll+Cha',
              Performance: 'roll+Cha',
              Persuasion: 'roll+Cha',
            },
            collections: {},
          },
        },
      },
      'Animate Objects': {
        rolls: {
          deadObjects: '0',
          prof: '4',
          chosenObjects: 'tinyObjects',
          'All Attack': 'dmgDice=chosenObjects,(number-deadObjects)#+atk',
          'All Attack, Flanking': 'dmgDice=chosenObjects,adv=1,(number-deadObjects)#+atk',
          tinyObjects: '1d4,dmgMod=4,number=10',
          smallObjects: '1d8,dmgMod=2,number=10',
          mediumObjects: '2d6,dmgMod=1,number=5',
          largeObjects: '2d10,dmgMod=2,number=2',
          hugeObjects: '2d12,dmgMod=4,number=1',
        },
        collections: {
          goblin: {
            collections: {},
            rolls: {
              ac: '15',
            },
          },
        },
      },
    },
    rolls: {},
  },
};

export default defaultCollectionsState;
