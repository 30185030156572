import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import Overlay from 'react-bootstrap/Overlay';
import useHoldOrClick from '../hooks/useHoldOrClick';

const ButtonWithHelpText = (props) => {
  const {
    action,
    children,
    className,
    info,
    ...otherProps
  } = props;

  const actionWrapper = () => {
    action();
  };

  const [show, setShow] = useState(false);
  const longPress = useHoldOrClick({
    holdAction: () => setShow(true),
    cancelHoldAction: () => setShow(false),
    clickAction: actionWrapper,
    delay: 500,
  });
  const target = useRef(null);

  if (info) {
    return (
      <>
        <Button
          ref={target}
          className={className}
          {...longPress}
          {...otherProps}
          onBlur={() => setShow(false)}
        >
          {children}
        </Button>
        <Overlay
          show={show}
          target={target.current}
        >
          <Tooltip>{info}</Tooltip>
        </Overlay>
      </>);
  } else {
    return (
      <Button
        className={className}
        onClick={actionWrapper}
        {...otherProps}>
        {children}
      </Button>
    );
  }
};

ButtonWithHelpText.propTypes = {
  action: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ButtonWithHelpText;
