import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CollectionLink from "./CollectionLink";
import SavedRoll from "./SavedRoll";
import SavedValue from './SavedValue';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons/faCompressArrowsAlt';
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons/faExpandArrowsAlt';
import CollapsedSavedValue from './CollapsedSavedValue';
import ConfirmationModal from '../common/ConfirmationModal';
import SelectCollectionModal from '../common/SelectCollectionModal';

const SortableRow = sortableContainer(({ ...props }) => (<Row {...props} />));
const SortableDivContainer = sortableContainer(({ ...props }) => <div {...props} />);
const SortableCollectionLink = sortableElement(({ ...props }) => <CollectionLink {...props} />);
const SortableSavedValue = sortableElement(({ ...props }) => (<SavedValue {...props} />));
const SortableSavedRoll = sortableElement(({ ...props }) => (<SavedRoll {...props} />));
const SortableCollapsedValue = sortableElement(({ ...props }) => (<CollapsedSavedValue {...props} />));

const Collections = ({ collectionState, history, roll, saveRoll, selectCalculator, selectHistory, setFormula }) => {
  const [newCollectionName, setNewCollectionName] = useState(null);
  const [newRollName, setNewRollName] = useState(null);
  const [collapsed, setCollapsed] = useState({ rolls: false, values: false });
  const [modalProperties, setModalProperties] = useState({
    action: () => {}, show: false, text: ''
  });
  const doWithConfirmation = (action, text) => setModalProperties({
    action,
    show: true,
    text
  });
  const [selectCollectionProperties, setSelectCollectionProperties] = useState({
    action: () => {},
    collections: collectionState.collections,
    show: false,
  });
  const moveCollectionWithModal = (collectionToMove) => setSelectCollectionProperties({
    ...selectCollectionProperties,
    action: (destination) => collectionState.move(collectionToMove, destination),
    pathToExclude: `${collectionState.activeCollection.join('/')}/${collectionToMove}`.replace(/^Root\//, '/'),
    show: true,
    title: `Move ${collectionToMove}`,
  });

  const activate = formula => () => {
    setFormula(formula);
    selectCalculator();
  };
  const removeRoll = name => () => {
    collectionState.removeRoll(name);
  };
  const rollHandler = ({ name, formula }) => (suppliedValues, targetCollections) => {
    const successCallback = ({ result, rolls, sideEffects }) => {
      collectionState.setLastRoll({
        historyIndex: history.length - 1,
        name,
        result,
        rolls,
        sideEffects,
        targetCollections
      });
    };
    const suppliedString = Object.entries(suppliedValues)
      .filter(([, value]) => value.length > 0)
      .map(([name, value]) => `,${name}=${value}`)
      .join('');
    const sentFormula = `${formula}${suppliedString}`;
    roll(sentFormula, successCallback);
  };
  const CollapseButton = ({ collection }) => (<Button
    className="btn-left-of-row"
    onClick={() => setCollapsed({ ...collapsed, [collection]: true })}
    variant="outline-info"
  ><FontAwesomeIcon icon={faCompressArrowsAlt} />
  </Button>);
  const ExpandButton = ({ collection }) => (<Button
    className="btn-left-of-row"
    onClick={() => setCollapsed({ ...collapsed, [collection]: false })}
    variant="outline-info"
  ><FontAwesomeIcon icon={faExpandArrowsAlt} />
  </Button>);

  const {
    activeCollection,
    currentCollection,
    getRollsWithDetails,
    removeCurrentCollection,
    renameCurrentCollection,
  } = collectionState;

  const rollMetadata = getRollsWithDetails();

  const rollsAndValues = Object.entries(currentCollection.rolls);
  const rolls = rollsAndValues.filter(([name]) =>
    rollMetadata[name].type !== 'value'
  );
  const values = rollsAndValues.filter(([name]) =>
    rollMetadata[name].type === 'value'
  );

  const getRollInfo = roll => (typeof roll === 'string'
    ? { formula: roll }
    : {
      ...roll,
      gotoResultInHistory: roll.historyIndex
        ? () => {
          selectHistory(roll.historyIndex + 1);
        }
        : null,
    });

  const handleMoveCollection = ({ oldIndex, newIndex }) => {
    if (typeof oldIndex !== 'number' || oldIndex < 0 || oldIndex > values.length) {
      return;
    }
    const collectionName = Object.entries(currentCollection.collections)[oldIndex][0];
    collectionState.moveChildCollection(collectionName, newIndex);
  };
  const handleMoveSavedItem = collectionType => {
    const collection = { rolls, values }[collectionType];
    return ({ oldIndex, newIndex }) => {
      if (typeof oldIndex !== 'number' || oldIndex < 0 || oldIndex > values.length) {
        return;
      }
      const itemName = collection[oldIndex][0];
      const destName = collection[newIndex][0];
      const newIndexInFullList = rollsAndValues.findIndex(([name]) => name === destName);
      collectionState.moveRoll(itemName, newIndexInFullList);
    };
  };

  return (
    <Container>
      <h1>Collections</h1>
      {
        activeCollection.length === 0
          ? null
          :
          <Breadcrumb>{activeCollection.map(
            (collectionName, i) => {
              if (i < activeCollection.length - 1) {
                return (<Breadcrumb.Item
                  key={i}
                  href={`#${collectionName}`}
                  onClick={() => collectionState.navigateToParent(activeCollection.length - i - 1)}
                >{collectionName}</Breadcrumb.Item>);
              } else {
                return (<Breadcrumb.Item key={i} active>{collectionName}</Breadcrumb.Item>);
              }
            }
          )}
          </Breadcrumb>
      }
      <Row className="mb-2">
        <Col>
          <Button
            onClick={() => setNewCollectionName(collectionState.createCollection())}
            variant="primary"
          >Create Child Collection</Button>
        </Col>
        {activeCollection.length <= 1
          ? null
          : <>
            <Col>
              <Button
                onClick={() => doWithConfirmation(removeCurrentCollection, "Click OK to delete this collection.")}
                variant="danger"
              >Remove Current Collection</Button>
            </Col>
            <Col>
              <Button
                onClick={renameCurrentCollection}
                variant="secondary"
              >Rename Current Collection</Button>
            </Col>
          </>
        }
      </Row>
      <SortableDivContainer
        className="list-group"
        onSortEnd={handleMoveCollection}
        useDragHandle
      >
        {Object.entries(currentCollection.collections).map(
          ([collectionName, coll], i) => (
            <SortableCollectionLink
              key={collectionName}
              collectionName={collectionName}
              duplicate={() => collectionState.duplicate(collectionName)}
              forceEditable={collectionName === newCollectionName}
              index={i}
              move={() => moveCollectionWithModal(collectionName)}
              navTo={() => collectionState.navigateTo(collectionName)}
              rename={collectionState.renameChildCollection}
            />
          ))}
      </SortableDivContainer>
      <h3 className="mt-4">Saved Rolls and Values</h3>
      <Button
        block
        onClick={() => setNewRollName(collectionState.createRoll())}
        variant="success"
      >Create Roll</Button>
      {values.length === 0 ? null : !collapsed.values
        ? <SortableRow
          axis="xy"
          className="mt-3"
          onSortEnd={handleMoveSavedItem('values')}
          useDragHandle
        >
          <CollapseButton collection="values" />
          {values.map(([ name, roll ], valueIndex) => {
            const formula = typeof roll === 'string' ? roll : roll.formula;
            return (
              <SortableSavedValue
                key={name}
                activateFormulaInCalculator={activate(formula)}
                formula={formula}
                index={valueIndex}
                name={name}
                remove={removeRoll(name)}
                rename={collectionState.renameRoll}
                rollMetadata={rollMetadata[ name ]}
                saveRoll={saveRoll}
                value={rollMetadata[ name ].value}
              />
            );
          })}
        </SortableRow>
        : <SortableRow
          axis="xy"
          className="mt-3"
          onSortEnd={handleMoveSavedItem('values')}
        >
          <ExpandButton collection="values" />
          {values.map(([ name, roll ], valueIndex) => (
            <SortableCollapsedValue
              key={name}
              index={valueIndex}
              name={name}
              value={rollMetadata[name].value.join(', ')}
            />
          ))}
        </SortableRow>
      }
      {rolls.length > 0
        ?
        <SortableRow
          axis="xy"
          className="mt-2"
          onSortEnd={handleMoveSavedItem('rolls')}
          useDragHandle
        >
          {collapsed.rolls
            ? <ExpandButton collection="rolls"/>
            : <CollapseButton collection="rolls"/>
          }
          {rolls.map(([name, roll], rollIndex) => {
            const {formula, result, gotoResultInHistory} = getRollInfo(roll);
            return (<SortableSavedRoll
                key={name}
                activateFormulaInCalculator={activate(formula)}
                childCollections={currentCollection.collections}
                clone={() => collectionState.cloneRoll(name)}
                collapsed={collapsed.rolls}
                convertPathStringToPathArray={collectionState.convertPathStringToPathArray}
                formula={formula}
                getCollectionByPath={collectionState.getCollectionByPath}
                gotoResultInHistory={gotoResultInHistory}
                index={rollIndex}
                move={newIndex => collectionState.moveRoll(name, newIndex)}
                name={name}
                rename={collectionState.renameRoll}
                remove={removeRoll(name)}
                result={result}
                roll={rollHandler({name, formula})}
                rollMetadata={rollMetadata[name]}
                saveRoll={saveRoll}
                scrollTo={newRollName === name}
                setScrollTo={setNewRollName}
              />
            );
          })}
        </SortableRow>
        : null
      }
      <ConfirmationModal
        setShow={show => setModalProperties({ ...modalProperties, show })}
        {...modalProperties}
      />
      <SelectCollectionModal
        setShow={show => setSelectCollectionProperties({
          ...selectCollectionProperties,
          show
        })}
        {...selectCollectionProperties}
      />
    </Container>
  );
};

Collections.propTypes = {
  collectionState: PropTypes.object.isRequired,
  roll: PropTypes.func.isRequired,
  saveRoll: PropTypes.func.isRequired,
  selectCalculator: PropTypes.func.isRequired,
  selectHistory: PropTypes.func.isRequired,
  setFormula: PropTypes.func.isRequired,
};
export default Collections;
