import React from 'react';
import './App.css';
import Calculator from './components/Calculator';
import History from './components/History';
import Collections from './components/Collections';
import Settings from './components/Settings';
import AppState from './state/AppState';
import CollectionsState from './state/CollectionsState';
import { rollFormula } from 'roll-a-d6';

const App = () => {

  const {
    activeTab, setActiveTab,
    formula, setFormula,
    result, setResult,
    rolls, setRolls,
    scrollToItem, setScrollToItem,
    history, setHistory, pushHistoryItem,
  } = AppState();
  const collectionState = CollectionsState();


  const clearHistory = () => {
    setHistory([]);
    collectionState.clearHistory();
  };

  const defaultRollSuccessCallback = ({ result, resultRange, rolls }) => {
    setResult(resultRange || result);
    setRolls(rolls);
  };
  const roll = (expression = formula, successCallback = defaultRollSuccessCallback) => {
    try {
      const helpersMap = {
        ...collectionState.sharedRolls,
        ...collectionState.parentRolls,
        ...collectionState.getRolls(),
      };
      const rollResult = rollFormula(expression, helpersMap);
      successCallback({ expression, ...rollResult });

      const helpers = [
        ...Object.entries(collectionState.sharedRolls).map(([name, helper]) => ({ name, source: 'Shared', value: helper })),
        ...Object.entries(collectionState.parentRolls).map(([name, helper]) => ({ name, source: 'Parent', value: helper })),
        ...Object.entries(collectionState.getRolls()).map(([name, helper]) => ({ name, source: `/${collectionState.activeCollection.join('/')}`, value: helper })),
      ].filter(({ name }, i, ary) => !ary.slice(i + 1).some(helper => helper.name === name))
        .filter(({ name }) => (rollResult.allMacros || {}).hasOwnProperty(name))
        .filter(({ name }) => !rollResult.macros.hasOwnProperty(name));

      pushHistoryItem({ formula: expression, ...rollResult, helpers });
    } catch (err) {
      console.error(err);
      setResult(['Error!']);
      setRolls([]);
    }
  };

  const calculatorMacros = {
    ...collectionState.getRolls(collectionState.sharedRolls),
    ...(Object.keys(collectionState.currentCollection.rolls).length > 0
      ? collectionState.getRolls()
      : collectionState.parentRolls)
  };

  return (<div className="App">
    {activeTab === 'calculator'
      ? <Calculator
        formula={formula}
        result={result}
        rolls={rolls}
        macros={calculatorMacros}
        setFormula={setFormula}
        saveRoll={() => collectionState.saveRoll(formula)}
        roll={() => roll()} // use default params since it's called from an event handler
      />
      : null}
    <div style={activeTab !== 'collections' ? { display: 'none' } : {}}>
      <Collections
        collectionState={collectionState}
        history={history}
        roll={roll}
        saveRoll={collectionState.addRollToCurrentCollection}
        selectCalculator={() => setActiveTab('calculator')}
        selectHistory={(item) => {
          setScrollToItem(item);
          setActiveTab('history');
        }}
        setFormula={setFormula}
      />
    </div>
    <div style={activeTab !== 'history' ? { display: 'none' } : {}}>
      <History
        clearHistory={clearHistory}
        history={history}
        scrollTo={scrollToItem}
        setScrollTo={setScrollToItem}
      />
    </div>
    <div style={activeTab !== 'settings' ? { display: 'none' } : {}}>
      <Settings />
    </div>
    <div className="app-nav">
      <button
        onClick={() => setActiveTab('collections')}
        className={activeTab === 'collections' ? 'active' : ''}
      >
        Collections
      </button>
      <button
        onClick={() => setActiveTab('calculator')}
        className={activeTab === 'calculator' ? 'active' : ''}
      >
        Calculator
      </button>
      <button
        onClick={() => setActiveTab('history')}
        className={activeTab === 'history' ? 'active' : ''}
      >
        History
      </button>
      <button
        onClick={() => setActiveTab('settings')}
        className={activeTab === 'settings' ? 'active' : ''}
      >
        Settings
      </button>
    </div>
  </div>);
};

export default App;
