import { useStateWrapper } from '../AppState';
import defaultCollectionsState from './collectionsState.default';
import { navigation as Navigation } from './navigation';
import { organization as Organization } from './organization';
import { rolls as Rolls } from './rolls';

/**
 * @typedef SavedRoll
 * @type {object|string}
 * @property {string} formula - The formula, e.g., '1d6'
 * @property {Array.<ResultEntry|ResultRange>} result - The last result rolled, e.g., 6
 * @property {object.<number,string[]>} rolls - Roll results - key is number of sides, value
 *           is an array of roll results (e.g., '4(d6)') in the order they were rolled
 * @property {number} historyIndex - The index in the history corresponding to the last result
 */

/**
 * @typedef SavedCollection
 * @type {object}
 * @property {SavedCollections} collections - Child collections
 * @property {object.<string, SavedRoll>} rolls - Rolls and values in this collection
 */

/**
 * @typedef SavedCollections
 * @type {object.<string, SavedCollection>}
 */

const CollectionsState = () => {
  const [collections, setCollections] = useStateWrapper('collections', defaultCollectionsState);
  const [activeCollection, setActiveCollection] = useStateWrapper('activeCollection', ['Root']);

  const stateHooks = { collections, setCollections, activeCollection, setActiveCollection };

  const navigation = Navigation(stateHooks);
  const rolls = Rolls(stateHooks, navigation);
  const organization = Organization(stateHooks, navigation);

  return {
    activeCollection,
    collections,
    ...navigation,
    ...rolls,
    ...organization,
  };
};

export default CollectionsState;
