export default [
  {
    version: '0.1',
    date: '2019-Aug-5',
    features: [
      'Initial Release',
      'Calculator, history, save rolls'
    ]
  },
  {
    version: '0.2',
    date: '2019-Aug-6',
    features: [
      'Persist state to local storage',
      'Style updates',
      'Reduce scope of saved macro values to only be within the same macro'
    ],
  },
  {
    version: '0.3',
    date: '2019-Aug-7',
    features: [
      'Initial support for && and || operators',
      'Style updates',
      'Create an editable textarea when clicking in the formula box',
      'Allow it to not just look like a phone',
      'Improved deployment procedure'
    ],
  },
  {
    version: '0.4',
    date: '2019-Aug-8',
    features: [
      'Disable some instances of accidental zooming',
      'Fix for | and & - characters were not passing validation',
      'Fix prompt for unassigned variables'
    ]
  },
  {
    version: '0.5',
    date: '2019-Aug-8',
    features: [
      'Disable some instances of accidental zooming',
      'Fix for | and & - characters were not passing validation',
      'Fix prompt for unassigned variables'
    ]
  },
  {
    version: '0.5.1',
    date: '2019-Aug-9',
    features: [
      'Style updates',
    ]
  },
  {
    version: '0.6',
    date: '2019-Aug-12',
    features: [
      'Behind the scenes improvements',
      'Nested collections',
      'Source formulas from active collection, shared collection, or parent collections of the active collection',
      'Default collections on first load',
      'Change macro buttons to pull from collections',
      'When pressing dice buttons automatically prefix d# with 1 if there isn\'t a number',
      'Show roll info on macro buttons',
      'Settings Tab that allows deleting all localStorage data for the app',
    ],
  },
  {
    version: '0.7',
    date: '2019-Aug-13',
    features: [
      'Only pull in helper assignments from macros when we reference the macro by name in our expression or a dependency',
      'Behind the scenes: JSDoc added for many complicated functions',
      'Changelog added',
    ],
  },
  {
    version: '0.8',
    date: '2019-Aug-15',
    features: [
      'Duplicate collections',
      'Move collections - needs work',
      'Rename collections',
    ],
  },
  {
    version: '0.9',
    date: '2019-Aug-21',
    features: [
      'Global macro instance operator - {}',
      '^ Operator - means "assume this value is 0 if it\'s not set"',
      'Roll saved rolls from the Collections page',
    ],
  },
  {
    version: '0.10',
    date: '2019-Aug-23',
    features: [
      'Dice button images',
      'Inline editing for saved rolls',
      'Link from the Collections page to the associated History entry',
      'Preserve location for the Collections, History, and Settings tab when navigating back and forth',
    ],
  },
  {
    version: '0.11',
    date: '2019-Aug-24',
    features: [
      'Fix negative numbers',
      'Styling fix',
      'Division is now floored, to better support D&D - a non-flooring division operator may be added later',
    ],
  },
  {
    version: '0.11.1',
    date: '2019-Aug-25',
    features: [
      'Fix negative numbers properly',
      'Ensure that an expression\'s helper macros by a given name are used before macros from a collection',
    ],
  },
  {
    version: '0.12',
    date: '2019-Aug-25',
    features: [
      'Separate values and rolls',
      'Supply values (including with formulas) to saved rolls in-line',
    ],
  },
  {
    version: '0.13',
    date: '2019-Sep-8',
    features: [
      'Improved look & feel',
      'Supply collections when collection formulas use macro@target notation',
      'Expansion operators (#+, #*, #&, #|)',
      'Support for a single unknown variable (using a value of ?)',
      'Inline inputs for the values needed by collection saved rolls, with defaults',
      'Go to history from collection roll and highlight the history entry',
    ],
  },
  {
    version: '0.14',
    date: '2019-Sep-9',
    features: [
      'Tooltips on calculator buttons',
      'Buttons for max and min',
      'Style fixes and color changes',
      'Layout improvements',
    ],
  },
  {
    version: '0.15',
    date: '2019-Sep-18',
    features: [
      'Show helper info in history items',
      'Fix bug that crashed the whole app permanently if an invalid formula was entered',
      'Add tests for default collection state',
      'Add extra info if an error is encountered when rolling (both saved and calculator)',
      'List 5E PHB armor and weapons in default collection',
      'Nested collections inside Shared (all get loaded)',
      'Support renaming saved rolls, values, and collections',
      'Support cloning saved rolls',
      'Drag and drop to reorder saved rolls, values, and collections',
      'Buttons to collapse/expand all saved rolls or all saved values',
    ],
  },
  {
    version: '0.16',
    date: '2020-Feb-18',
    features: [
      'Move core rolling library into its own repository',
      'Only dice that were actually rolled as part of the formula are listed now',
    ],
  },
  {
    version: '0.17',
    date: '2020-Mar-2',
    features: [
      'Side effect support: $varname',
      'Side effect operator: +=',
      'Side effect operator: -=',
      'Side effect operator: := to assign the value',
      'Side effects: support recursing with internal side effects',
      'Side effects: support modifying current collection',
      'Side effects: support modifying target collection',
      'Targeted variables now dynamically update as the target value updates',
    ],
  },
  {
    version: '0.18',
    date: '2020-Mar-3',
    features: [
      'Rudimentary import / export'
    ],
  },
  {
    version: '0.19',
    date: '2020-Mar-21',
    features: [
      'Moved to demo.dicerollr.com',
      'Added an FAQ, link to the product roadmap, etc..',
      'Name change to DiceRollr',
      'Fix bug in default atk saved roll. The new formula is roll[0]==20->dmgDice[0]+dmgDice[1]+dmgMod;(roll[0]==1->0;(roll[0]+bonus>=ac@Defender->dmgDice[0]+dmgMod;0))',
      'Fix for the collapsed changelog still taking up space on the page',
      'Add Animate Objects default collection'
    ]
  },
  {
    version: '0.20',
    date: '2022-Sep-26',
    features: [
      'Bug fix: blank formulas no longer cause the entire app to crash'
    ]
  }
];
