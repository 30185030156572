import React from 'react';
import TreeView from './TreeView';

const CollectionNode = ({ action, children }) => (
  <button className="list-group-item list-group-item-action" onClick={action}>
    {children}
  </button>
);

const logCollectionSelector = false;
const log = message => {
  if (logCollectionSelector) {
    console.log(message);
  }
};

const CollectionTreeViewSelector = ({ collections, pathToExclude, selectCollection }) => {
  const collectionReduceHelper = path => (accum, [collectionName, collection]) => {
    const collectionPath = `${path}/${collectionName}`;
    if (pathToExclude === collectionPath) {
      log('Excluded path found');
      return accum;
    }
    log(`Path: ${collectionPath}`);
    return [
      ...accum,
      {
        collectionPath,
        node: <CollectionNode
          key={`cnode-${collectionPath}`}
          action={() => selectCollection(collectionPath)}
        >{collectionName}</CollectionNode>,
        children: Object.entries(collection.collections)
          .reduce(collectionReduceHelper(collectionPath), []),
      },
    ];
  };
  const treeViewData = {
    collectionPath: '/',
    node: <CollectionNode
      key="cnode-Root"
      action={() => selectCollection('/')}
    >Root</CollectionNode>,
    children: Object.entries(collections.Root.collections)
      .reduce(collectionReduceHelper(''), []),
  };

  return <TreeView 
    Container={({ children }) => <div className="list-group">{children}</div>}
    data={[treeViewData]}
  />;
};

export default CollectionTreeViewSelector;
