import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormulaEditor } from './FormulaEditor';
import CollectionChooserButton from './CollectionChooserButton';
import { objectMakerReduceHelper } from '../../lib/helpers';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import {Form} from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalculator,
  faClone,
  faTrashAlt,
  faEquals,
  faHistory,
} from '@fortawesome/free-solid-svg-icons';
import '../../icons.css';
import './savedRoll.css';
import { formulaTokenizer, formulaValidator } from 'roll-a-d6';
import ResultBuilder, { resultPropType } from '../common/ResultBuilder';
import ClickableValue from '../common/ClickableValue';
import DragHandle from '../common/DragHandle';
import ConfirmationModal from '../common/ConfirmationModal';
import RollButton from './RollButton';
import VariableEntry from './VariableEntry';

const { getTargetCollection, stripPrefix, stripSuffix } = formulaTokenizer;

const SavedRoll = ({
  activateFormulaInCalculator,
  childCollections,
  clone,
  collapsed,
  convertPathStringToPathArray,
  formula,
  getCollectionByPath,
  gotoResultInHistory,
  name,
  remove,
  rename,
  roll,
  rollMetadata,
  result,
  saveRoll,
  scrollTo,
  setScrollTo,
}) => {

  // values supplied by user or by chosen collections in the "unknown variables" section
  const [suppliedValues, setSuppliedValues] = useState({});
  // selected target collections
  const [targetCollections, setTargetCollections] = useState({});
  const [renaming, setRenaming] = useState(false);
  const [modalProperties, setModalProperties] = useState({
     action: () => {}, show: false, text: ''
  });
  const doWithConfirmation = (action, text) => setModalProperties({
    action,
    show: true,
    text
  });

  const ref = useRef(null);
  useEffect(() => {
    if (scrollTo) {
      setRenaming(true);
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      return () => {
        setScrollTo(null);
      }
    }
  }, [ref, scrollTo, setScrollTo]);

  const valuesFromTargetedCollections = Object.entries(targetCollections).map(
    ([targetName, targetCollectionName]) => Object.entries(
      getCollectionByPath(convertPathStringToPathArray(targetCollectionName)).rolls)
      .filter(([name]) =>
        rollMetadata.unknownVariables.some(variable =>
          getTargetCollection(variable) === targetName
          && name === stripPrefix(stripSuffix(variable))
        ))
      .map(([name, formula]) => [`${name}@${targetName}`, formula])
  ).flat().reduce(objectMakerReduceHelper, {});

  const chosenCollectionSetter = targetCollection => chosenCollection => {
    setTargetCollections({
      ...targetCollections,
      [targetCollection]: chosenCollection,
    });
    if (chosenCollection !== ''
      && !rollMetadata.targetedCollections[targetCollection].some(
        valueName => childCollections[chosenCollection].rolls.hasOwnProperty(valueName)
      )) {
      window.alert('The selected collection does not have any of the needed values.')
    }
  };

  const hasVariables =
    Object.entries(rollMetadata.targetedCollections).length > 0 ||
    rollMetadata.unknownVariables.length > 0;

  const missingVariables = rollMetadata.unknownVariables
      .filter(variable =>
        !(rollMetadata.noPromptVariables.includes(variable)
          && (typeof suppliedValues[variable] !== 'string' || suppliedValues[variable].length === 0))
        && !(typeof suppliedValues[variable] === 'string' && formulaValidator.validateClause(suppliedValues[variable], 0))
        && !(Object.keys(valuesFromTargetedCollections).includes(variable))
      );


  const hasMissingVariables = missingVariables.length > 1 ||
    (missingVariables.length === 1 &&
      Object.values(suppliedValues).some(value => value === '?')
    );
  const onlyUnknownVariable = !hasMissingVariables && missingVariables.length === 1
    ? missingVariables[0]
    : null;

  const rollButtonProps = {
    hasMissingVariables,
    onlyUnknownVariable,
    roll,
    suppliedValues,
    targetCollections,
    valuesFromTargetedCollections,
  };

  if (collapsed) {
    return (
      <Card className="saved-roll" ref={ref}>
        <Card.Header><DragHandle />{name}</Card.Header>
        <Card.Body>
          <RollButton {...rollButtonProps} size="xs" />
          {result
            ? <Badge
              style={{ width: '100%' }}
              title="Last rolled result"
              variant="success"
            >
              <ResultBuilder result={result}/>
            </Badge>
            : null
          }
        </Card.Body>
      </Card>
    );
  }

  return (<>
    <Card className="saved-roll" ref={ref}>
      <Card.Header
        as="h4"
      >
        {!renaming && <DragHandle/>}
        <ClickableValue
          forceEditable={scrollTo}
          setEditing={setRenaming}
          updateOnBlur
          setValue={newName => rename(name, newName)}
          value={name}
        />
        {!renaming && <FontAwesomeIcon
          className="danger trash-button"
          icon={faTrashAlt}
          onClick={() => doWithConfirmation(remove, 'Click OK to delete this roll.')}
          title="Delete this saved roll"
        />}
      </Card.Header>
      <Card.Body>
        <Row>
          {
            hasVariables
            &&
            <Col>
              {
                Object.entries(rollMetadata.targetedCollections).length === 0
                  ? null
                  : (<Form>
                    <Form.Group>
                      <Form.Label>Target Collections</Form.Label>
                      {
                        Object.entries(rollMetadata.targetedCollections).map(([ targetName ], i) =>
                          (<InputGroup
                            key={`unk-coll-${i}`}
                            as={Row}
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {targetName}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <CollectionChooserButton
                              collections={childCollections}
                              chosenCollection={targetCollections[ targetName ]}
                              setChosenCollection={chosenCollectionSetter(targetName)}
                            />
                          </InputGroup>))
                      }</Form.Group></Form>)
              }
              {
                rollMetadata.unknownVariables.length === 0
                  ? null
                  : <VariableEntry
                    onlyUnknownVariable={onlyUnknownVariable}
                    rollMetadata={rollMetadata}
                    setSuppliedValues={setSuppliedValues}
                    suppliedValues={suppliedValues}
                    valuesFromTargetedCollections={valuesFromTargetedCollections}
                  />
              }
            </Col>
          }
          <Col md={hasVariables ? 'auto' : null}>
            <Row>
              <RollButton {...rollButtonProps} size="lg" />
            </Row>
            {
              result
                ? <Row className="mt-1">
                  <Badge
                    style={{ width: '100%' }}
                    title="Last rolled result"
                    variant="success"
                  >
                    <FontAwesomeIcon
                      className="float-left"
                      icon={faEquals}
                    />
                    <ResultBuilder result={result} />
                    {
                      typeof gotoResultInHistory === 'function'
                        ? <FontAwesomeIcon
                          className="float-right"
                          icon={faHistory}
                          onClick={gotoResultInHistory}
                          title="Click to go to result in History"
                        />
                        : null
                    }
                  </Badge>
                </Row>
                : null
            }
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="text-muted">
        <FormulaEditor
          activateFormulaInCalculator={activateFormulaInCalculator}
          controlled={false}
          editButton={true}
          formula={formula}
          name={name}
          saveRoll={saveRoll}
        />
        <Button
          className="ml-2"
          onClick={activateFormulaInCalculator}
          variant="outline-info"
        >
          <FontAwesomeIcon
            alt="Load in calculator"
            icon={faCalculator}
          />
        </Button>
        <Button
          className="float-right"
          onClick={clone}
          variant="outline-secondary"
        >
          <FontAwesomeIcon
            alt="Duplicate this saved roll"
            icon={faClone}
          />
        </Button>
      </Card.Footer>
    </Card>
    <ConfirmationModal
      setShow={show => setModalProperties({ ...modalProperties, show })}
      {...modalProperties}
    />
  </>);
};

SavedRoll.propTypes = {
  activateFormulaInCalculator: PropTypes.func.isRequired,
  childCollections: PropTypes.object,
  clone: PropTypes.func.isRequired,
  formula: PropTypes.string.isRequired,
  gotoResultInHistory: PropTypes.func,
  name: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  rename: PropTypes.func.isRequired,
  roll: PropTypes.func.isRequired,
  rollMetadata: PropTypes.object.isRequired,
  result: resultPropType,
  saveRoll: PropTypes.func.isRequired,
  scrollTo: PropTypes.bool,
  setScrollTo: PropTypes.func,
};

export default SavedRoll;
