import React, { useState } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';

export const FormulaEditor = ({
  controlled = true,
  editButton,
  formula,
  name,
  readOnlyProp,
  saveRoll,
  setReadOnlyProp
}) => {
  const [readOnly, setReadOnly] = useState(true);
  const [editedFormulaValue, setEditedFormulaValue] = useState(formula);

  const isReadOnly = () => readOnlyProp != null ? readOnlyProp : readOnly;

  const makeWriteable = () => {
    if (!controlled) {
      setEditedFormulaValue(formula);
    }
    setReadOnly(false);
    if (typeof setReadOnlyProp === 'function') {
      setReadOnlyProp(false);
    }
  };
  const makeReadOnly = () => {
    if (!controlled && formula !== editedFormulaValue) {
      saveRoll({ name, formula: editedFormulaValue });
    }
    setReadOnly(true);
    if (typeof setReadOnlyProp === 'function') {
      setReadOnlyProp(true);
    }
  };
  const onChangeEventHandler = (event) => {
    if (controlled) {
      saveRoll({name, formula: event.target.value})
    } else {
      setEditedFormulaValue(event.target.value);
    }
  };

  return isReadOnly()
    ? (<span
      className="formula"
      onClick={makeWriteable}
    >
      {formula}
      {
        editButton
          ? <FontAwesomeIcon
            className="ml-2"
            icon={faEdit}
          />
          : null
      }
    </span>)
    : (
      <TextareaAutosize
        autoFocus
        className="formula-editor"
        onBlur={makeReadOnly}
        onChange={onChangeEventHandler}
        type="text"
        value={controlled ? formula : editedFormulaValue}
      />
      );
};

FormulaEditor.propTypes = {
  controlled: PropTypes.bool,
  editButton: PropTypes.bool,
  formula: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  readOnlyProp: PropTypes.bool,
  saveRoll: PropTypes.func.isRequired,
  setReadOnlyProp: PropTypes.func,
};
