import React, { useState } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ClickableValue from '../common/ClickableValue';
import DragHandle from '../common/DragHandle';
import './collectionLink.css';

const CollectionLink = ({ collectionName, duplicate, forceEditable, move, navTo, rename }) => {
  const [renaming, setRenaming] = useState(forceEditable);

  return (
    <div className="list-group-item list-group-item-action">
      <Row>
        <Col>
          <h3>
            {renaming
              ? null
              : <DragHandle/>
            }
            <ClickableValue
              forceEditable={forceEditable}
              onClick={navTo}
              setEditing={setRenaming}
              setValue={newName => rename(collectionName, newName)}
              updateOnBlur
              value={collectionName}
            />
          </h3>
        </Col>
        <Col>
          <ButtonGroup>
            <Button
              className="mr-1"
              onClick={event => {
                duplicate();
                event.stopPropagation();
              }}
              variant="outline-primary"
            >Duplicate</Button>
            <Button
              onClick={event => {
                move();
                event.stopPropagation();
              }}
              variant="outline-warning"
            >Move</Button>
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  );
};

export default CollectionLink;
