import React, { useState } from 'react';
import { Animated } from 'react-animated-css';
import "../../../node_modules/animate.css/animate.css";

const FAQ = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const animationDuration = 1000;
  const toggleVisible = () => {
    setIsVisible(!isVisible);
    setIsAnimating(true);
    setTimeout(() => setIsAnimating(false), animationDuration);
  };
  return (<div className="faq">
    <h2 className="collapseHeader" onClick={toggleVisible}>
      {
        isVisible
          ? '▼ FAQ (Click to Hide)'
          : '► FAQ (Click to Show)'
      }
    </h2>
    <Animated
      animateOnMount={false}
      animationIn="bounceInDown"
      animationOut="bounceOutUp"
      animationDuration={animationDuration}
      isVisible={isVisible}
      style={isVisible || isAnimating ? {} : { display: 'none' }}
    >
      <h3><strong>Q:</strong> What is this?</h3>
      <p>
        This is a demo of the upcoming DiceRollr app.
        Currently, it supports rolling sophisticated dice formulas that no other dice roller
        supports, like calculating the damage from 10 summons against a single creature in one roll.
        It also allows you to build your own completely custom collections of reusable dice rolls.
        For example, you could build a collection for each of your characters and put the rolls that
        are shared between them in the "Shared" collection.
      </p>
      <h3><strong>Q:</strong> How do I use it?</h3>
      <p>
        Build your rolls and collections in the "Collections" tab.
        You can roll from there, too.
      </p>
      <p>
        Check out the <a href="https://github.com/corvec/roll-a-d6/blob/master/README.md">
          core library README
        </a> for info on how to build formulas.
      </p>
      <p>For one-off dice rolls, use the "Calculator" tab.</p>
      <p>To see a list of rolls that you've made, the results of those rolls, as well as more details on those rolls, check out the "History" tab.</p>
      <h3><strong>Q:</strong> You said this is a demo. What will the full product look like?</h3>
      <p>
        Current plans involve mobile apps (iOS and Android) as well as a mobile and desktop friendly
        web app.
        The apps are planned to support the following, though they might not support it all initially:
      </p>
      <ul>
        <li>Cross-device collection syncing</li>
        <li>Local and server-side rolling</li>
        <li>
          Virtual tables, with the ability to share your rolls with other users and for GMs/DMs to
          request rolls from their players
        </li>
        <li>
          All the functionality that you need to keep your character fully tracked in the app, like
          notes, lists, etc...
        </li>
      </ul>
      <h3><strong>Q:</strong> Who's working on it?</h3>
      <p>
        This app and the <a href="https://github.com/corvec/roll-a-d6">
        core rolling library
      </a> has been developed by and is being developed by single developer, <a
        href="https://github.com/corvec">Corvec</a>.
      </p>
      <h3><strong>Q:</strong> What's coming next?</h3>
      <p>
        Check out the <a href="https://trello.com/b/v9pAcRPQ/dicerollr-roadmap">
        Project Roadmap</a>.
      </p>
      <h3><strong>Q:</strong> I have a feature request! How should I contact you?</h3>
      <p>
        Great! For now, please post your feature request on the <a
        href="https://github.com/corvec/roll-a-d6/issues">
        roll-a-d6 repository's Issues tracker.
      </a>
      </p>
      <h3><strong>Q:</strong> Will it be open-source?</h3>
      <p>
        I'm currently leaning toward keeping the app and server code closed source but open-sourcing
        the core library (roll-a-d6).
      </p>
    </Animated>
  </div>);
};

export default FAQ;
