import React from 'react';
import './settings.css';
import Changelog from './Changelog';
import FAQ from './FAQ';
import { saveAs } from 'file-saver';

const styles = {
  bold: { fontWeight: 'bold' },
};

const handleFile = async (file) => {
  console.log(file);
  if (!file) {
    return;
  }
  const confirm = window.confirm('Are you sure? You will lose all your data.');
  if (confirm) {
    const data = await file.text();
    if (data) {
      localStorage.setItem('collections', data);
      window.location.reload();
    }
  }
};

const Settings = () => (<div className="settings">
  <h1>Settings</h1>
  <div className="settings-body">
    <div className="settings-section">
      <h2>Export Data</h2>
      <p>NOTE: Currently, we only support full overwrites.</p>
      <button className="export" onClick={() => {
        const file = new File([localStorage.collections], 'DiceExport.json', { type: 'application/json;charset=utf-8' });
        saveAs(file);
      }}>Export</button>
      <h2>Import Data</h2>
      <p style={styles.bold}>Please export your data prior to importing data.</p>
      <label>Import: <input
        type="file"
        accept="application/json"
        onChange={event => handleFile(event.currentTarget.files[0])}
      />
      </label>
    </div>
    <div className="settings-section">
      <h2>Clear Local Data</h2>
      <p>Are you having issues with the app, or do you just want to restore the default settings?
        Try clearing your local data and then refreshing the page.
      </p>
      <p style={styles.bold}>
        NOTE: This is irreversible and you will lose all saved rolls and history.
      </p>
      <button className="clear-localstorage" onClick={() => {
        const confirm = window.confirm('Are you sure? You will lose all your data.');
        if (confirm) {
          localStorage.clear();
        }
      }}>Clear Localstorage</button>
    </div>
    <div className="settings-section">
      <FAQ />
    </div>
    <div className="settings-section">
      <Changelog />
    </div>
  </div>
</div>);

export default Settings;
