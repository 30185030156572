import immutably from 'immutably';
import { getPathString } from './helpers';
import {
  getUniqueName,
  objectMakerReduceHelper,
  removeFromObject,
  renameObjectKeyPreserveOrder,
} from '../../lib/helpers';
import { formulaParser, formulaTokenizer } from 'roll-a-d6';

const { getTargetCollection,  stripPrefix, stripSuffix, variableTargetsCollection } = formulaTokenizer;
const { getMacrosFromCollection, getRollMetadata } = formulaParser;

export const rolls = ({ activeCollection, setActiveCollection, collections, setCollections },
    { convertPathStringToPathArray, currentCollection, getCollectionByPath, getCurrentPath }) => {

  const addRollToCurrentCollection = ({ formula, name }) => setCollections(
    immutably.set(
      collections,
      `${getPathString(activeCollection)}.rolls.${name}`,
      formula,
    )
  );

  const getCollectionPathForSideEffect = (sideEffectName, targetCollections) =>
    (variableTargetsCollection(sideEffectName))
      ? convertPathStringToPathArray(targetCollections[getTargetCollection(sideEffectName)])
      : activeCollection;

  const applySideEffects = ({ currentCollections = collections, sideEffects = {}, targetCollections = {} }) => {
    if (Object.entries(sideEffects).length === 0) {
      // no need to update the collections history with an operation that changes nothing
      return;
    }
    const newCollections = Object.entries(sideEffects).reduce((collectionsAccum, [name, value]) => {
      const collectionPath = getCollectionPathForSideEffect(name, targetCollections);
      const sideEffectBaseName = stripPrefix(stripSuffix(name));
      return immutably.set(
        collectionsAccum,
        `${getPathString(collectionPath)}.rolls.${sideEffectBaseName}`,
        `${value}`,
      )
    }, currentCollections);
    setCollections(newCollections);
  };
  const clearHistoryReferenceFromRoll = roll =>
    typeof roll === 'string'
      ? roll
      : removeFromObject('historyIndex', roll);

  const clearCollectionHistoryReduceHelper = (accum, [collectionName, collection]) => ({
    ...accum,
    [collectionName]: {
      ...collection,
      rolls: Object.entries(collection.rolls).map(
        ([rollName, roll]) => [rollName, clearHistoryReferenceFromRoll(roll)]
      ).reduce(objectMakerReduceHelper, {}),
      collections: Object.entries(collection.collections).reduce(clearCollectionHistoryReduceHelper, {})
    },
  });

  /**
   * Fetch the formula from a roll from one of the two ways they're stored:
   * {
   *   roll: '1d6',
   *   roll2: {
   *     formula: '1d6'
   *   }
   * }
   *
   * @param {string|{formula: string}} roll
   * @return {string}
   */
  const getRollFormula = roll => typeof roll === 'string' ? roll : roll.formula;

  const getRolls = (rolls = currentCollection.rolls) => Object.fromEntries(Object.entries(rolls).map(
    ([name, roll]) => [name, getRollFormula(roll)]
  ).filter(([_, formula]) => formula));

  const getChildRolls = collection => Object.values(collection.collections)
    .map(child => ({
      ...getRolls(child.rolls),
      ...getChildRolls(child),
    }))
    .reduce((accum, childRolls) => ({
      ...accum,
      ...childRolls,
    }), {});


  const parentRolls = [...Array(activeCollection.length - 1).keys()].reduce(
    (accum, _, i) =>
      ({
        ...accum,
        ...getRolls(getCollectionByPath(activeCollection.slice(0, i + 1)).rolls),
      })
    , {});
  const sharedRolls = collections.Root.collections.hasOwnProperty('Shared')
    ? {
      ...getRolls(collections.Root.collections.Shared.rolls),
      ...getChildRolls(collections.Root.collections.Shared),
    }
    : [];


  const getRollDetails = (roll, macrosFromCollection) => {
    const formula = getRollFormula(roll);
    const rollDetails = getRollMetadata(formula, macrosFromCollection);
    return { formula, ...rollDetails };
  };

  const getRollsWithDetails = (rolls = currentCollection.rolls) => {
    const macrosFromCollection = getMacrosFromCollection({
      ...sharedRolls,
      ...parentRolls,
      ...getRolls(),
    });
    return Object.entries(rolls).map(
      ([name, roll]) => [name, getRollDetails(roll, macrosFromCollection)]
    ).reduce(objectMakerReduceHelper, {});
  };

  return {
    addRollToCurrentCollection,
    // clear the historyIndex field out of all saved rolls
    clearHistory: () => setCollections(Object.entries(collections).reduce(
      clearCollectionHistoryReduceHelper,
      {}
    )),
    cloneRoll: (name) => {
      const newName = getUniqueName(name, currentCollection.rolls);
      const newRolls = Object.entries(currentCollection.rolls).reduce(
        (accum, [rollName, roll]) => (
          rollName === name
            ? {
              ...accum,
              [rollName]: roll,
              [newName]: roll,
            }
            : {
              ...accum,
              [ rollName ]: roll,
            }
        ), {});
      return setCollections(immutably.set(
        collections,
        `${getPathString(activeCollection)}.rolls`,
        newRolls
      ));
    },
    /**
     * Create a new roll and add it to the current collection
     * @returns {string} The name of the newly added roll
     */
    createRoll: () => {
      const name = getUniqueName('New Roll', currentCollection.rolls)
      const formula = '1d20';
      addRollToCurrentCollection({ name, formula });
      return name;
    },
    getRollFormula,
    getRolls,
    getRollsWithDetails,
    moveToEnd: rollName => {
      const newRolls = [
        ...Object.entries(currentCollection.rolls)
          .filter(([name]) => rollName !== name),
        [rollName, currentCollection.rolls[rollName]],
      ].reduce(objectMakerReduceHelper, {});
      return setCollections(immutably.set(
        collections,
        `${getPathString(activeCollection)}.rolls`,
        newRolls
      ));
    },
    moveRoll: (rollName, newIndex) => {
      const rollsArray = Object.entries(currentCollection.rolls)
        .filter(([name]) => name !== rollName);
      const newRolls = [
        ...rollsArray.slice(0, newIndex),
        [rollName, currentCollection.rolls[rollName]],
        ...rollsArray.slice(newIndex),
      ].reduce(objectMakerReduceHelper, {});
      return setCollections(immutably.set(
        collections,
        `${getPathString(activeCollection)}.rolls`,
        newRolls
      ));
    },
    removeRoll: rollName => {
      const { [rollName]: _, ...newRolls } = currentCollection.rolls;
      return setCollections(immutably.set(
        collections,
        `${getPathString(activeCollection)}.rolls`,
        newRolls
      ));
    },
    renameRoll: (oldName, newName, handleConflict) => {
      if (currentCollection.rolls.hasOwnProperty(newName)) {
        if (typeof handleConflict === 'function') {
          handleConflict();
        }
      } else {
        const newRolls = renameObjectKeyPreserveOrder(oldName, newName, currentCollection.rolls);
        setCollections(immutably.set(
          collections,
          `${getPathString(activeCollection)}.rolls`,
          newRolls
        ));
      }
    },
    parentRolls,
    saveRoll: formula => {
      if (formula.length > 0) {
        const name = window.prompt('Name of saved roll', '');
        if (name && !currentCollection.rolls[name]) {
          addRollToCurrentCollection({ formula, name });
        } else if (name) {
          const msg = 'Click OK to overwrite existing roll by that name';
          if (window.confirm(msg)) {
            addRollToCurrentCollection({ formula, name });
          }
        }
      }
    },
    /**
     *
     * @param {object} p
     * @param {number} p.historyIndex
     * @param {string} p.name The name of the roll
     * @param {ResultEntry[]} p.result An array containing the results of each expression
     * @param {object.<number, string[]>} p.rolls An array containing the numbers rolled on any
     *         dice in the expression
     * @param {object} [p.sideEffects={}] Side effects as a result of the roll
     * @param {object} [p.targetCollections] Selected Target collections for side effects with @target
     */
    setLastRoll: ({ historyIndex, name, result, rolls, sideEffects = {}, targetCollections }) => {
      const rollObj = currentCollection.rolls[name];
      const newRollObj = typeof rollObj === 'string'
        ? {
          formula: rollObj,
          historyIndex,
          result,
          rolls,
        }
        : {
          ...rollObj,
          historyIndex,
          result,
          rolls,
        };
      const updatedCollections = immutably.set(
        collections,
        `${getPathString(activeCollection)}.rolls.${name}`,
        newRollObj
      );
      if (Object.entries(sideEffects).length > 0) {
        applySideEffects({ currentCollections: updatedCollections, sideEffects, targetCollections });
      } else {
        setCollections(updatedCollections);
      }
    },
    sharedRolls,
  };
};
