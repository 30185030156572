import React from 'react';
import PropTypes from 'prop-types';

const ResultBuilder = ({ className, result }) => {
  if (typeof result === 'string') {
    return <span className={className}>result</span>;
  }
  if (Array.isArray(result) && result.length > 0) {
    if (result[0] === null || result[0] === undefined) {
      return <span>No Result</span>
    } else if (typeof result[0] !== 'object') {
      return <span>{result.join(', ')}</span>;
    } else if (typeof result[0] === 'object') {
      if (result.length === 1) {
        return <span title={`value of ${result[0].variable || 'variable'} had no impact`}>
          <strong>{result[0].result.join(', ')}</strong>
        </span>;
      }
      return (<ul className="results-set">{
        result.map(
          ({ minValue, maxValue, result: res, variable }, i) => {
            return (<li key={i}>
              {typeof variable === 'string' ? variable : ''} {
              minValue > 0 ? `${minValue}` : ''}
              {
                minValue === 0
                  ? `${maxValue} or below`
                  : typeof maxValue === 'number'
                  ? `-${maxValue}` : ' or above'
              }: {res.join(', ')}
            </li>);
          })
      }</ul>);
    }
  }
  if (typeof result === 'object' && result.hasOwnProperty(0)) {
    return (
      <ul className="results-set">
        {
          Object.entries(result)
            .map(([ value, results ], i) =>
              <li key={i}>
                >= {value}: {results.join(', ')}
              </li>
            )

        }
      </ul>);
  }

  return <span>Cannot parse result</span>;
};

const resultsType = PropTypes.arrayOf(
  PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]).isRequired
).isRequired;

export const resultPropType = PropTypes.oneOfType([
  resultsType,
  PropTypes.objectOf(resultsType).isRequired,
  PropTypes.arrayOf(PropTypes.shape({
    minValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    maxValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    result: resultsType,
    variable: PropTypes.string,
  }).isRequired).isRequired
]);

ResultBuilder.propTypes = {
  className: PropTypes.string,
  result: resultPropType.isRequired,
};

export default ResultBuilder;
