import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

// currently just a drop down but should do more later

const CollectionChooserButton = ({ collections, chosenCollection, setChosenCollection }) => {
  return (
    <DropdownButton
      title={chosenCollection || <em>Choose Collection</em>}
    >
      <Dropdown.Item
        onClick={() => setChosenCollection('')}
      >None</Dropdown.Item>
      {
        Object.entries(collections).map(([collection], i) => (
          <Dropdown.Item
            key={`coll-${i}`}
            onClick={() => setChosenCollection(collection)}
          >{collection}</Dropdown.Item>
        ))
      }
    </DropdownButton>
  );
};

export default CollectionChooserButton;
