import React, { useState } from 'react';
import { Animated } from 'react-animated-css';
import "../../../node_modules/animate.css/animate.css";
import changes from './changes';

const Changelog = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const animationDuration = 1000;
  const toggleVisible = () => {
    setIsVisible(!isVisible);
    setIsAnimating(true);
    setTimeout(() => setIsAnimating(false), animationDuration);
  };
  return (<div className="changelog">
    <h2 className="collapseHeader" onClick={toggleVisible}>
      {
        isVisible
          ? '▼ Changelog (Click to Hide)'
          : '► Changelog (Click to Show)'
      }
    </h2>
    <Animated
      animateOnMount={false}
      animationIn="bounceInDown"
      animationOut="bounceOutUp"
      animationDuration="500"
      isVisible={isVisible}
      style={isVisible || isAnimating ? {} : { display: 'none' }}
    >
      <ul className="change-list">
        {/* eslint-disable-next-line fp/no-mutating-methods */
          [...changes].reverse().map(({version, date, features}, i) => (<li
            key={i}
            className="change"
          >
            <div className="info">Version <strong>{version}</strong><br />{date}</div>
            <ul className="features-list">{ features.map(
              (feature, i) => (
                <li key={i} className="feature">{feature}</li>)
            )}</ul>
          </li>))}
      </ul>
    </Animated>
  </div>);
};

export default Changelog;
